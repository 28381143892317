import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Notification from "../components/Notification"

const IndexPage = () => (
  <Layout>
    <SEO title="TDCS Rosenheim" />

    <Notification />

    <h1>Praxisspektrum</h1>

    <p>
      In der Praxis Dr. Badoiu George Vichente werden alle Formen der
      Schmerzzustände, Migräne, Tinnitus, Bewegungsstörungen, neurologische
      Erkrankungen wie: Epilepsie, Morbus Parkinson, Multipler Sklerose,
      Dystonie, Spastik und nach einem Schlaganfall, Schizophrenie, Alzheimer
      sowie affektive Störungen wie Depression, PTBS, Angst, Rehabilitation bei
      Lähmungen und Sucht behandelt.
    </p>

    <h2>Methoden</h2>
    <ul>
      <li>transkranielle Gleichstrombehandlung (tDCS)</li>
      <li>craniale Elektrostimulation (CES)</li>
      <li>repetitive transkranielle Magnetstimulation (rTMS)</li>
      <li>Pharmakotherapie</li>
      <li>Achtsamkeitsbasierte Schmerztherapie (ABST)</li>
      <li>Ionophorese</li>
      <li>
        Digitale Psychometrie, Unterstützung der Psychodiagnostik (über 100
        Tests)
      </li>
      <li>Autogenes Training (AT) - Hypnose und Selbsthypnosetraining</li>
      <li>Hypnose und Selbsthypnosetraining</li>
      <li>Progressive Muskelrelaxation nach Jacobsen</li>
      <li>Noninvasive Neuromodulation (rTMS, tDCS)</li>
      <li>
        Beratung zu Arbeitsunfähigkeiten und beruflicher Wiedereingliederung
      </li>
      <li>Beratung zu Rehabilitationsmaßnahmen</li>
      <li>Beratung in Fragen der Erwerbsunfähigkeitsberentung</li>
    </ul>
  </Layout>
)

export default IndexPage
